import React from 'react';
import { Button, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { withTranslation } from 'react-i18next';
import { FaFacebook, FaLinkedin, FaReddit, FaRegClipboard, FaRegEnvelope } from 'react-icons/fa';
import AccessibleOverlayTrigger from '../../common/AccessibleOverlayTrigger';

class ShareButton extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
      super(props);
      this.state = {
          show: false,
          copied: false,
      }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    const linkToShareTitle = document.title;
    const linkToShare = window.location.href;

    const handleClose = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
    const handleCopy = () => {
      this.setState({ copied: true });
      navigator.clipboard.writeText(linkToShare);
    }
    
    return (
      <>
        <Button onClick={ handleShow } className='share__link hidden' size='sm' variant='light'>
          { t('share.button.share') }
        </Button>
  
        <Modal
          show={ this.state.show }
          onHide={ handleClose }
          size="lg"
          aria-labelledby="info-title"
          centered
        >
          <Modal.Header closeButton closeLabel={ t('modal.close.button.label') }>
            <Modal.Title id="info-title">
              { t('share.modal.header.title') }
            </Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <Container>
              <Row>
                <Col className="d-flex justify-content-center">
                  <AccessibleOverlayTrigger placement='bottom' overlay={
                    <Tooltip>
                      { t(`share.modal.button.clipboard.tooltip`) }
                    </Tooltip>
                  }>
                    <span onClick={ handleCopy } tabIndex="0" className="share-button-link" target="_blank" rel="noopener noreferrer">
                      <FaRegClipboard />
                      <span className="sr-only">{t('link.externalLink.label')}</span>
                    </span>
                  </AccessibleOverlayTrigger>
                </Col>
                <Col className="d-flex justify-content-center">
                  <AccessibleOverlayTrigger placement='bottom' overlay={
                    <Tooltip>
                      { t(`share.modal.button.tooltip`) + "e-mail" }
                    </Tooltip>
                  }>
                    <a href={`mailto:?subject=${linkToShareTitle}&body=${linkToShare}`} className="share-button-mail" target="_blank" rel="noopener noreferrer">
                      <FaRegEnvelope />
                      <span className="sr-only">{t('link.externalLink.label')}</span>
                    </a>
                  </AccessibleOverlayTrigger>
                </Col>
                <Col className="d-flex justify-content-center">
                  <AccessibleOverlayTrigger placement='bottom' overlay={
                    <Tooltip>
                      { t(`share.modal.button.tooltip`) + "X" }
                    </Tooltip>
                  }>
                    <a href={`https://x.com/share?text=${linkToShareTitle}&url=${linkToShare}`} className="share-button-x" target="_blank" rel="noopener noreferrer">
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                      <span className="sr-only">{t('link.externalLink.label')}</span>
                    </a>
                  </AccessibleOverlayTrigger>
                </Col>
                <Col className="d-flex justify-content-center">
                  <AccessibleOverlayTrigger placement='bottom' overlay={
                    <Tooltip>
                      { t(`share.modal.button.tooltip`) + "LinkedIn" }
                    </Tooltip>
                  }>
                    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkToShare}`} className="share-button-linkedin" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin />
                      <span className="sr-only">{t('link.externalLink.label')}</span>
                    </a>
                  </AccessibleOverlayTrigger>
                </Col>
                <Col className="d-flex justify-content-center">
                  <AccessibleOverlayTrigger placement='bottom' overlay={
                    <Tooltip>
                      { t(`share.modal.button.tooltip`) + "Facebook" }
                    </Tooltip>
                  }>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${linkToShare}`} className="share-button-fb" target="_blank" rel="noopener noreferrer">
                      <FaFacebook />
                      <span className="sr-only">{t('link.externalLink.label')}</span>
                    </a>
                  </AccessibleOverlayTrigger>
                </Col>
                <Col className="d-flex justify-content-center">
                  <AccessibleOverlayTrigger placement='bottom' overlay={
                    <Tooltip>
                      { t(`share.modal.button.tooltip`) + "Reddit" }
                    </Tooltip>
                  }>
                    <a href={`https://reddit.com/submit?url=${linkToShare}&title=${linkToShareTitle}`} className="share-button-reddit" target="_blank" rel="noopener noreferrer">
                      <FaReddit />
                      <span className="sr-only">{t('link.externalLink.label')}</span>
                    </a>
                  </AccessibleOverlayTrigger>
                </Col>
              </Row>

              <Row className="share-modal-link-label">
                <Col>
                  { t(`share.modal.link.label`) + (this.state.copied ? t(`share.modal.link.label.copied`) : "") }
                </Col>
              </Row>

              <Row className="share-modal-link-input">
                <Col>
                  <input type="text" disabled value={linkToShare}></input>
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Col className="d-flex justify-content-center">
              <Button onClick={ handleClose }>
                { t('share.modal.button.close') }
              </Button>
            </Col>

          </Modal.Footer>
        </Modal>
      </>
    );
  };
}

export default withTranslation()(ShareButton);
