import React from 'react';
import PubroSpinner from '../../common/PubroSpinner';
import Card from 'react-bootstrap/Card';
import Citation, { CitationType } from "../common/Citation";
import IssueCover from '../../issue/IssueCover';
import { formatIssueLabel } from '../../common/utils';
import { useTranslation } from 'react-i18next';
import PublicationCommonDetailsSidePanel from '../common/PublicationCommonDetailsSidePanel';
import { Link } from 'react-router-dom';
import PbnExportButton from '../common/PbnExportButton';
import ShareButton from '../common/ShareButton';
import { Col, Row } from 'react-bootstrap';

/**
 * Renders article details displayed on the right side.
 * Expects the following props:
 * - article
 * - previewMode – when set to true disables all controls
 *   that allow processing only published items (citation, export)
 */
export default function ArticleDetailsSidePanel({article, pbnExportUrl, previewMode, api}) {

  const { t } = useTranslation();

  if (!article) {
    return <PubroSpinner />
  }
  return (
    <>
      <Card className='section image-section'>
        <Card.Body>
            <Link to={`/journals/${article.issueInfo.journalInfo.id}`}>
              <div className="text-center">
                <IssueCover coverId={article.issueInfo.coverId} thumbnail={false} emptyAlt={true} />
              </div>
              <div className="issue-journal-title"><strong>{article.issueInfo.journalInfo.title}</strong></div>
            </Link>
            <Link to={`/issues/${article.issueInfo.id}`}>
              <div className="issue-title">{formatIssueLabel(article.issueInfo)}</div>
            </Link>
        </Card.Body>
      </Card>
      <Card className='section'>
        <Card.Body>
          <Row>
            <Col className="space-apart">
              <Citation citationType={CitationType.ARTICLE} publication={article} disabled={previewMode} api={api}/>
              <PbnExportButton pbnExportUrl={pbnExportUrl} disabled={previewMode}/>
              <ShareButton />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className='section'>
        <Card.Body>
          {article.pageRange && !article.number &&
            <Row>
              <Col sm={6} md={12} lg={6}>{t('article.details.pagerange.prefix')}</Col>
              <Col sm={6} md={12} lg={6}><strong data-page-range>{article.pageRange}</strong></Col>
            </Row>
          }
          {article.number &&
            <Row>
              <Col sm={6} md={12} lg={6}>{t('article.details.number.prefix')}</Col>
              <Col sm={6} md={12} lg={6}><strong data-number>{article.number}</strong></Col>
            </Row>
          }
        </Card.Body>
      </Card>
      <PublicationCommonDetailsSidePanel publication={article} externalIdLabelPrefix='dictionary.article_external_id_type.' />
    </>
  );
}
