import React from 'react';
import PubroSpinner from '../../common/PubroSpinner';
import BookCover from './../book/BookCover';
import {LinkContainer} from "react-router-bootstrap";
import Card from 'react-bootstrap/Card';
import Citation, { CitationType } from "../common/Citation";
import PublicationCommonDetailsSidePanel from '../common/PublicationCommonDetailsSidePanel';
import { useTranslation } from 'react-i18next';
import PbnExportButton from '../common/PbnExportButton';
import ShareButton from '../common/ShareButton';
import { Col, Row } from 'react-bootstrap';
import ProcessMath from '../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../common/utils';
import FormattedContent from '../common/FormattedContent';

/**
 * Renders publication details for chapters,
 * displayed on the right side. Expects the following props:
 * - chapter
 * - previewMode – when set to true disables all controls
 *   that allow processing only published items (citation, export)
 */
export default function ChapterDetailsSidePanel({chapter, pbnExportUrl, previewMode, api}) {

  const { t } = useTranslation();

  if (!chapter) {
    return <PubroSpinner />
  }

  return (
    <>
      <Card className='section image-section'>
        <Card.Body>
          <div className='text-center'>
            <LinkContainer to={`/books/${chapter.bookInfo.id}`}>
              <a><BookCover coverId={chapter.bookInfo.coverId} thumbnail={false} bookTitle={chapter.bookInfo.title}/></a>
            </LinkContainer>
          </div>
        </Card.Body>
      </Card>
      <Card className='section'>
        <Card.Body>
          <Row>
            <Col className="space-apart">
              <Citation citationType={CitationType.CHAPTER} publication={chapter} disabled={previewMode} api={api}/>
              <PbnExportButton pbnExportUrl={pbnExportUrl} disabled={previewMode}/>
              <ShareButton />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <LinkContainer to={`/books/${chapter.bookInfo.id}`} className="publication-details-sidebar-link">
            <a className="publication-details-sidebar-link">
              <strong data-book-title>
                <ProcessMath><FormattedContent as='span' content={transformAsciiMathDelimiter(chapter.bookInfo.title)} /></ProcessMath>
              </strong>
            </a>
          </LinkContainer>
          {chapter.pageRange &&
            <div>{t('chapter.details.pageRange.prefix')}<strong>{chapter.pageRange}</strong></div>
          }
        </Card.Body>
      </Card>
      <PublicationCommonDetailsSidePanel publication={chapter} externalIdLabelPrefix='dictionary.chapter_external_id_type.' />
    </>
  );
}
