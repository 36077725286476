
export const pl = {
  translation: {
    "logo.main": "Biblioteka Nauki",
    "logo.main.alt": "Logo Biblioteki Nauki",
    "header.skiptocontent": "Przejdź do treści",
    "header.gotositemap": "Przejdź do mapy strony",
    "navbar.menuitem.resources": "Zasoby",
    "navbar.menuitem.aboutproject": "O Bibliotece Nauki",
    "navbar.menuitem.help": "Pomoc",
    "navbar.menuitem.contact": "Kontakt",
    "navbar.menuitem.forpublishers": "Dla wydawców",
    "navbar.menuitem.joinpublishers": "Dołącz do wydawców",
    "navbar.menuitem.faq": "FAQ",
    "navbar.menuitem.login": "Logowanie",
    "navbar.menuitem.privacypolicy": "Polityka prywatności",
    "navbar.menuitem.accessibility": "Deklaracja dostępności",
    "navbar.menuitem.termsandconditions": "Regulamin",
    "navbar.menuitem.reporterror": "Zgłoś błąd",
    "navbar.menuitem.sitemap": "Mapa strony",
    "navbar.menuitem.switchlanguage.tooltip": "Zmień język witryny",
    "navbar.menuitem.switchlanguage.current.pl": "Obecnie Polski",
    "navbar.menuitem.switchlanguage.current.en": "Obecnie Angielski",
    "navbar.menuitem.switchlanguage.button.label.pl": "PL",
    "navbar.menuitem.switchlanguage.button.labellong.pl": "Polski",
    "navbar.menuitem.switchlanguage.button.tooltip.pl": "Zmień język witryny na polski",
    "navbar.menuitem.switchlanguage.button.label.en": "EN",
    "navbar.menuitem.switchlanguage.button.labellong.en": "English",
    "navbar.menuitem.switchlanguage.button.tooltip.en": "Change website language to English",
    "navbar.flag.alt": "Flaga kraju dla języka",
    "breadrumb.aria-label": "Scieżka okruszków",
    "cookies-banner.text": "Korzystamy z plików <inEnglish>cookies</inEnglish>. Warunki przechowywania i dostępu do <inEnglish>cookies</inEnglish> możesz ustawić w Twojej przeglądarce.",
    "cookies-banner.learnmore": "Dowiedz się więcej",
    "cookies-banner.button.close": "Zamknij",
    "footer.copyright" : "Copyright",
    "footer.logo.alt.uw" : "Uniwersytet Warszawski",
    "footer.logo.alt.icm" : "Interdyscyplinarne Centrum Modelowania Matematycznego i Komputerowego UW",
    "footer.logo.alt.fe" : "Fundusze Europejskie",
    "footer.logo.alt.rp" : "Rzeczpospolita Polska",
    "footer.logo.alt.ue" : "Unia Europejska",
    "meta.site-name": "Biblioteka Nauki",
    "meta.home.title": "Strona główna",
    "meta.home.description": "Przeszukuj i czytaj tysiące publikacji naukowych za darmo! Największy otwarty zbiór artykułów i książek naukowych w Polsce.",
    "meta.default.title": "Biblioteka Nauki",
    "meta.default.description": "Przeszukuj i czytaj tysiące publikacji naukowych za darmo! Największy otwarty zbiór artykułów i książek naukowych w Polsce.",
    "meta.publishers.title": "Lista wydawców",
    "meta.publishers.description": "Lista wydawców współpracujących z ICM UW, udostępniających publikacje naukowe w Bibliotece Nauki",
    "meta.journals.title": "Lista czasopism",
    "meta.journals.description": "Lista czasopism udostępnionych w Bibliotece Nauki",
    "meta.books.title": "Lista książek",
    "meta.books.description": "Lista książek udostępnionych w Bibliotece Nauki",
    "meta.journal.issues.description": "Lista wydań",
    "welcome.title": "Biblioteka Nauki",
    "welcome.description": "Biblioteka Nauki – strona główna",
    "welcome.search.backgroundVideo.play": "Odtwórz",
    "welcome.search.backgroundVideo.pause": "Wstrzymaj",
    "welcome.stats.openAccess.header": "Otwarty dostęp",
    "welcome.stats.restrictedAccess.header": "WBN",
    "welcome.stats.restrictedAccess.tooltip": "Dostęp dla: {{organizationName}}; Zasoby archiwalne Wirtualnej Biblioteki Nauki: {{publisherNames}}",
    "welcome.scientificFields.all": "Wszystkie",
    "welcome.scientificFields.all.ariaLabel": "Pokaż wszystkie najnowsze/najpopularniejsze",
    "welcome.scientificFields.ariaLabelPostfix": ", Pokaż najnowsze/najpopularniejsze z dziedziny",
    "welcome.newest.header": "Najnowsze",
    "welcome.popular.header": "Najpopularniejsze",
    "welcome.books.header": "Książki",
    "welcome.books.empty": "Brak książek",
    "welcome.books.emptyScientificField": "Brak książek z wybranej dziedziny",
    "welcome.books.loadingFailed": "Próba załadowania książek nie powiodła się.",
    "welcome.articles.header": "Artykuły",
    "welcome.articles.empty": "Brak artykułów",
    "welcome.articles.emptyScientificField": "Brak artykułów z wybranej dziedziny",
    "welcome.articles.loadingFailed": "Próba załadowania artykułów nie powiodła się.",
    "welcome.journals.header": "Czasopisma",
    "welcome.journals.empty": "Brak czasopism",
    "welcome.journals.emptyScientificField": "Brak czasopism z wybranej dziedziny",
    "welcome.journals.loadingFailed": "Próba załadowania czasopism nie powiodła się.",
    "welcome.databases.header": "Bazy danych",
    "welcome.publishers.header": "Wydawcy",
    "welcome.publications.show-all": "Wyświetl wszystkie",
    "welcome.articles.show-all.ariaLabel": "Wyświetl wszystkie artykuły",
    "welcome.books.show-all.ariaLabel": "Wyświetl wszystkie książki",
    "welcome.publishers.show-all": "Wyświetl wszystkich",
    "welcome.publishers.show-all.ariaLabel": "Wyświetl wszystkich wydawców",
    "contact.title": "Kontakt",
    "contact.description": "Biblioteka Nauki – kontakt",
    "contact.helpdesk.mail.label": "Zapraszamy do kontaktu z nami:",
    "about-project.title": "O projekcie",
    "about-project.description": "Biblioteka Nauki – o projekcie",
    "join-publishers.title": "Dołącz do wydawców",
    "join-publishers.description": "Biblioteka Nauki – dołącz do wydawców",
    "join-publishers.terms": "Regulamin",
    "join-publishers.terms.download.ariaLabel": "Pobierz Regulamin",
    "join-publishers.agreement": "Umowa",
    "join-publishers.agreement.download.ariaLabel": "Pobierz Umowę",
    "join-publishers.download": "Pobierz",
    "sitemap.title": "Mapa strony",
    "sitemap.description": "Biblioteka Nauki - mapa strony",
    "sitemap.home": "Strona główna",
    "sitemap.search": "Wyszukiwanie",
    "sitemap.resources": "Zasoby",
    "sitemap.publishingcompanies": "Wydawcy",
    "sitemap.journals": "Czasopisma",
    "sitemap.books": "Książki",
    "sitemap.about": "O Bibliotece Nauki",
    "sitemap.help": "Pomoc",
    "sitemap.pubrodocumentation": "Dokumentacja ogólnodostępnej części Platformy",
    "sitemap.pueditdocumentation": "Dokumentacja dla zalogowanych użytkowników",
    "sitemap.embeddingdocumentation": "Embedowanie Biblioteki Nauki na własnej stronie www",
    "sitemap.apidocumentation": "Dokumentacja API",
    "sitemap.contact": "Kontakt",
    "sitemap.joinpublishers": "Dołącz do wydawców",
    "sitemap.faq": "FAQ",
    "sitemap.privacypolicy": "Polityka prywatności",
    "sitemap.accessibility": "Deklaracja dostępności",
    "sitemap.termsandconditions": "Regulamin",
    "library.tab.publishing-companies": "Wydawcy",
    "library.tab.journals": "Czasopisma",
    "library.tab.books": "Książki",
    "journal.search.input.placeholder": "Wyszukaj po tytule",
    "journal.search.button.title": "Szukaj czasopism",
    "journal.list.title": "Czasopisma",
    "journal.list.emptySearch": "Brak czasopism spełniających kryteria wyszukiwania",
    "book.search.input.placeholder": "Wyszukaj po tytule",
    "book.search.button.title": "Szukaj książek",
    "book.list.title": "Książki",
    "book.list.emptySearch": "Brak książek spełniających kryteria wyszukiwania",
    "publishing.company.list.title": "Wydawcy",
    "publishing.company.list.empty": "Brak wydawców",
    "publishing.company.list.emptySearch": "Brak wydawców spełniających kryteria wyszukiwania",
    "publishing.company.search.input.placeholder": "Wyszukaj po nazwie",
    "publishing.company.search.button.title": "Szukaj wydawców",
    "publishing.company.details.header.publisher": "Wydawca",
    "publishing.company.details.header.basicinfo": "Informacje ogólne",
    "publishing.company.details.header.journals": "Czasopisma",
    "publishing.company.details.header.books": "Książki",
    "publishing.company.details.additionalnames": "Nazwy dodatkowe",
    "publishing.company.details.addresses": "Adresy",
    "publishing.company.details.phones": "Telefony",
    "publishing.company.details.emails": "Adresy e-mail",
    "publishing.company.details.urls": "Wydawca w sieci",
    "publishing.company.details.openaccesspolicy": "<inEnglish>Open access policy</inEnglish>",
    "publishing.company.details.nodata": "Brak danych",
    "publishing.company.details.latest.issue.header": "Ostatnie wydanie",
    "publishing.company.details.latest.issue.prefix": "Nr",
    "publishing.company.details.latest.book.header": "Ostatnia książka",
    "publishing.company.logo.alt": "Logo wydawcy",
    "publishing.company.logo.default.alt": "Ikona wydawcy",
    "journal.details.section.header.basic-information": "Informacje podstawowe",
    "journal.details.section.header.descriptions": "Opis czasopisma",
    "journal.details.section.header.scientific-fields": "Dyscypliny naukowe",
    "journal.details.section.header.indexation": "Indeksacja i wskaźniki",
    "journal.details.section.header.contact": "Kontakt",
    "journal.details.section.header.for-authors": "Dla autorów i recenzentów",
    "journal.details.section.header.issues": "Wydania",
    "journal.details.header.journal": "Czasopismo",
    "journal.details.header.issues": "Wydania",
    "journal.details.header.maintitletranslations": "Tłumaczenia tytułu głównego",
    "journal.details.header.additionaltitles": "Tytuły dodatkowe",
    "journal.details.header.description": "Opis",
    "journal.details.header.descriptiontranslations": "Tłumaczenia opisu",
    "journal.details.header.externalidentifiers": "Identyfikatory zewnętrzne",
    "journal.details.header.additionaldescriptions": "Informacje dodatkowe",
    "journal.details.header.contact": "Dane teleadresowe",
    "journal.details.header.links": "Czasopismo w sieci",
    "journal.details.header.databases": "Bazy indeksujące czasopismo",
    "journal.details.header.scores": "Wskaźniki",
    "journal.details.language.prefix": "Język czasopisma",
    "journal.details.issuefrequency.prefix": "Częstotliwość wydawania",
    "journal.details.last.issue": "Ostatnie wydanie",
    "journal.details.last.issue.empty": "Brak ostatniego wydania",
    "journal.details.for-authors.empty": "Brak informacji dla autorów i recenzentów",
    "journal.details.contact.empty": "Brak informacji kontaktowych",
    "journal.details.indexation-and-scores.empty": "Brak informacji o indeksacji i wskaźnikach",
    "journal.details.latest.issue.header": "Ostatnie wydanie",
    "journal.details.linked-journals": "Powiązane tytuły",
    "journal.list.empty": "Brak czasopism",
    "journal.database.list.empty": "Brak baz danych",
    "journal.database.journals.header": "Czasopisma",
    "journal.database.name.AGRO": "AGRO",
    "journal.database.name.BAZ_EKON": "BazEkon",
    "journal.database.name.BAZ_TECH": "BazTech",
    "journal.database.name.CEJSH": "CEJSH",
    "journal.database.name.DML_PL": "DML-PL",
    "journal.database.name.PSJD": "PSJD",
    "journal.database.description.AGRO": "czasopisma z dziedziny nauk rolniczych i przyrodniczych",
    "journal.database.description.BAZ_EKON": "czasopisma z zakresu nauk ekonomicznych",
    "journal.database.description.BAZ_TECH": "czasopisma z dziedziny nauk inżynieryjno-technicznych i ścisłych",
    "journal.database.description.CEJSH": "czasopisma z dziedziny nauk humanistycznych, społecznych,  teologicznych i sztuki",
    "journal.database.description.DML_PL": "czasopisma z zakresu matematyki",
    "journal.database.description.PSJD": "czasopisma z dziedziny nauk medycznych i nauk o zdrowiu",
    "issue.details.header.issue": "Wydanie",
    "issue.details.header.articles": "Artykuły",
    "issue.list.empty": "Brak wydań",
    "issue.list.filter.year": "Rok wydania",
    "issue.list.filter.year.all": "Wszystkie wydania",
    "issue.details.journal": "Czasopismo",
    "issue.details.year": "Rok",
    "issue.details.volume": "Wolumin",
    "issue.details.number": "Numer",
    "issue.cover.alt": "Okładka wydania",
    "issue.cover.default.alt": "Ikona wydania",
    "article.list.empty": "Brak artykułów",
    "article.details.journalandissue.journal.title.prefix": "Czasopismo: ",
    "article.details.journalandissue.journal.issn.prefix": "ISSN: ",
    "article.details.journalandissue.issue.year.prefix": "Rok: ",
    "article.details.journalandissue.issue.volume.prefix": "Wolumin: ",
    "article.details.journalandissue.issue.number.prefix": "Numer: ",
    "article.details.pagerange.prefix": "Strony: ",
    "article.details.number.prefix": "ID artykułu:",
    "article.details.kind": "Artykuł",
    "book.list.empty": "Brak książek",
    "book.details.header.tableofcontents": "Spis treści",
    "book.details.header.description": "Opis",
    "book.details.releaseplace.prefix": "Miejsce wydania: ",
    "book.details.releaseyear.prefix": "Rok wydania: ",
    "book.details.pagecount.prefix": "Liczba stron: ",
    "book.details.kind.simplebook": "Książka autorska (współautorska)",
    "book.details.kind.collectivework": "Praca zbiorowa",
    "book.cover.alt": "Okładka książki",
    "book.cover.default.alt": "Ikona książki",
    "bookparts.list.empty": "Brak części książek",
    "chapter.list.empty": "Brak rozdziałów",
    "chapter.details.pageRange.prefix": "Strony: ",
    "chapter.details.kind": "Rozdział",
    "citation.button.cite": "Cytowanie",
    "citation.header.title": "Cytowanie",
    "citation.footer.button.bib.title": "BibTeX",
    "citation.footer.button.bib.extension": "(BiB)",
    "citation.footer.button.ris.title": "EndNote, RefMan, ProCite",
    "citation.footer.button.ris.extension": "(RIS)",
    "citation.footer.button.ariaLabel.prefix": "Pobierz cytowanie - format",
    "share.button.share": "Udostępnij",
    "share.modal.header.title": "Udostępnij",
    "share.modal.link.label": "Link bezpośredni",
    "share.modal.link.label.copied": " (skopiowano do schowka)",
    "share.modal.button.tooltip": "Udostępnij przez ",
    "share.modal.button.clipboard.tooltip": "Skopiuj do schowka",
    "share.modal.button.close": "Zamknij",
    "wbnAccessIcon.tooltip.ARTICLE": "Artykuł",
    "wbnAccessIcon.tooltip.SIMPLE_BOOK": "Książka autorska",
    "wbnAccessIcon.tooltip.COLLECTIVE_WORK": "Praca zbiorowa",
    "wbnAccessIcon.tooltip.CHAPTER": "Rozdział",
    "wbnAccessIcon.tooltip.ISSUE": "Wydanie",
    "wbnAccessIcon.tooltip.JOURNAL": "Czasopismo",
    "wbnAccessIcon.tooltip.PUBLISHER": "Wydawca",
    "wbnAccessIcon.tooltip.UNKNOWN": "Publikacja/wydawca",
    "wbnAccessIcon.tooltip": "z kolekcji Wirtualnej Biblioteki Nauki, dostęp dla: ",
    "publication.details.previewmode": "Podgląd wersji roboczej",
    "publication.details.title.header": "Tytuł",
    "publication.details.non.book.abstracts.header": "Abstrakt",
    "publication.details.book.abstracts.header": "Streszczenie",
    "publication.details.chapter.abstracts.header": "Streszczenie",
    "publication.details.bibliography.header": "Bibliografia",
    "publication.details.affiliations.header": "Afiliacje",
    "publication.details.keywords.header": "Słowa kluczowe",
    "publication.details.license.unknown": "Brak danych",
    "publication.details.publicationattributes.header": "Cechy publikacji",
    "publication.details.language.prefix": "Główny język publikacji",
    "publication.details.remarks.header": "Informacje dodatkowe",
    "publication.details.index.header": "Indeks",
    "publication.details.researchdata.link": "Link do danych badawczych",
    "publication.details.scientificdisciplines.header": "Dyscypliny naukowe",
    "publication.details.scientificdisciplines.link.tooltip": "Wyszukaj publikacje z ustawioną dyscypliną naukową",
    "publication.details.attributes.reviewedpublication": "Publikacja recenzowana",
    "publication.details.attributes.postconferencepublication": "Publikacja pokonferencyjna",
    "publication.details.attributes.reprintpublication": "Przedruk",
    "publication.details.attributes.translationpublication": "Tłumaczenie",
    "publication.details.fulltext.accessibility.request": "Problem z dostępnością? Poproś o plik zgodny z WCAG",
    "publication.details.diamondaccess.tooltip": "Diamentowy otwarty dostęp - czasopismo nie pobiera opłat od autorów za umieszczenie publikacji w otwartym dostępie.",
    "details.publication.contributors.name": "Imię i nazwisko",
    "details.publication.contributors.publications": "Inne publikacje",
    "details.publication.contributors.bio": "Biogram",
    "details.publication.contributors.email": "E-mail",
    "details.publication.contributors.degrees": "Tytuł/stopnie naukowe",
    "details.publication.contributors.affiliations": "Afiliacje",
    "details.publication.contributors.orcid": "ORCID",
    "details.publication.contributors.corresponding": "Korespondencyjny",
    "details.publication.contributors.corresponding.true": "TAK",
    "details.publication.contributors.corresponding.false": "NIE",
    "details.publication.contributors.section.noInfo": "Brak informacji",
    "details.publication.contributors.corresponding.asterisk": "* ",
    "details.publication.contributors.more.tooltip": "Pokaż wszystkich",
    "details.publication.contributors.more.tooltip.AUTHOR": "autorów",
    "details.publication.contributors.more.tooltip.EDITOR": "redaktorów",
    "details.publication.contributors.more.tooltip.SCIENTIFIC_EDITOR": "redaktorów naukowych",
    "details.publication.contributors.more.tooltip.REVIEWER": "recenzentów",
    "details.publication.contributors.more.tooltip.TRANSLATOR": "tłumaczy",
    "details.publication.contributors.less.tooltip": "Zwiń listę",
    "contributor.modal.header": "Informacje o autorze",
    "contributor.modal.button.close": "Zamknij",
    "search.title": "Wyszukiwanie",
    "search.description": "Przeszukuj i czytaj tysiące publikacji naukowych w otwartym dostępie! Serwis tworzony we współpracy z wydawcami.",
    "search.form.select.async.loading.message": "Ładowanie...",
    "search.form.select.nooptions.message": "Brak opcji",
    "search.form.advanced.keyword.minimum.length.message": "Wprowadź co najmniej trzy znaki",
    "search.form.advanced.pcj.minimum.length.message": "Wprowadź co najmniej trzy znaki",
    "search.form.simple.stats.articles": "artykułów",
    "search.form.simple.stats.journals": "czasopism",
    "search.form.simple.stats.books": "książek",
    "search.form.simple.stats.oncclicence": "publikacji na licencji CC",
    "search.form.simple.title.main": "Przeszukaj największą bazę naukową w Polsce",
    "search.form.simple.title.subtitle": "100% publikacji pełnotekstowych",
    "search.form.simple.button.clear.label": "Wyczyść",
    "search.form.simple.button.submit.label": "Szukaj",
    "search.form.simple.scope.all": "Wszędzie",
    "search.form.simple.scope.journals": "W czasopismach",
    "search.form.simple.scope.books": "W książkach",
    "search.form.simple.placeholder": "Szukane słowa",
    "search.form.simple.advanced-search.link": "Wyszukiwanie zaawansowane",
    "search.form.in.publishing.company.input.placeholder": "Szukaj w zasobach wydawcy",
    "search.form.in.publishing.company.button.title": "Szukaj w zasobach wydawcy",
    "search.form.in.journal.input.placeholder": "Szukaj w czasopiśmie",
    "search.form.in.journal.button.title": "Szukaj w czasopiśmie",
    "search.result.list.header": "Wyniki wyszukiwania",
    "search.result.list.total.label": "Liczba wyników:",
    "search.result.list.empty": "Brak wyników",
    "search.result.sort.select.label": "Sortuj",
    "search.result.sort.score.asc": "po trafności rosnąco",
    "search.result.sort.score.desc": "po trafności malejąco",
    "search.result.sort.date.asc": "po dacie rosnąco",
    "search.result.sort.date.desc": "po dacie malejąco",
    "search.result.pagesize.select.label": "Wyników na stronę",
    "search.result.list.item.frame.article.header": "Artykuł",
    "search.result.list.item.frame.simplebook.header": "Książka autorska (współautorska)",
    "search.result.list.item.frame.collectivework.header": "Praca zbiorowa",
    "search.result.list.item.frame.chapter.header": "Rozdział",
    "search.result.list.item.abstract.header": "Abstrakt",
    "search.result.list.item.summary.header": "Streszczenie",
    "search.result.list.item.fulltext.header": "Pełny tekst",
    "search.result.list.item.download.label": "Pobierz",
    "search.result.list.item.license.label": "Swoboda wykorzystania",
    "search.result.list.item.scientificdisciplines.link.tooltip": "Wyszukaj publikacje z ustawionym filtrem: dyscyplina naukowa",
    "search.result.list.item.contributors.link.tooltip": "Wyszukaj publikacje z ustawionym filtrem: kontrybutor",
    "search.pagination.first.ariaLabel": "Pierwsza strona wyników wyszukiwania",
    "search.pagination.previous.ariaLabel": "Poprzednia strona wyników wyszukiwania",
    "search.pagination.current.label": "(obecna strona)",
    "search.pagination.page.ariaLabelPostfix": "strona wyników wyszukiwania",
    "search.pagination.next.ariaLabel": "Następna strona wyników wyszukiwania",
    "search.pagination.last.ariaLabel": "Ostatnia strona wyników wyszukiwania",
    "search.form.advanced.publication-type.label": "Rodzaj publikacji:",
    "search.form.advanced.publication-type.article": "artykuły",
    "search.form.advanced.publication-type.chapter": "rozdziały w pracach zbiorowych",
    "search.form.advanced.publication-type.book": "książki autorskie (współautorskie) i prace zbiorowe",
    "search.form.advanced.open-resources.label": "Szukaj tylko w otwartych zasobach",
    "search.form.advanced.contributors.label": "Kontrybutorzy",
    "search.form.advanced.contributors.placeholder": "imię, nazwisko lub ORCID",
    "search.form.advanced.published-date.label": "Data publikacji pomiędzy",
    "search.form.advanced.published-date-since.label": "Data publikacji od",
    "search.form.advanced.published-date-to.label": "Data publikacji do",
    "search.form.advanced.research-data-link.label": "Link do danych badawczych",
    "search.form.advanced.published-date.invalid": "Wprowadź datę w formacie RRRR-MM-DD, RRRR-MM lub RRRR",
    "search.form.advanced.published-date.order.invalid": "Data końcowa zakresu musi być taka sama lub późniejsza od daty początkowej",
    "search.form.advanced.published-date.placeholder": "RRRR, RRRR-MM lub RRRR-MM-DD",
    "search.form.advanced.expandable-panel.toggle.label": "Wyszukiwanie zaawansowane",
    "search.form.advanced.language.label": "Język główny publikacji",
    "search.form.advanced.licenses.label": "Swoboda wykorzystania",
    "search.form.advanced.licenses.allcc": "Wszystkie licencje Creative Commons",
    "search.form.advanced.scientific-disciplines.label": "Dyscypliny naukowe",
    "search.form.advanced.keywords.label": "Słowa kluczowe",
    "search.form.advanced.publishing-companies.label": "Wydawcy",
    "search.form.advanced.journals.label": "Czasopisma",
    "search.form.advanced.pcj.label.full": "Wydawca, Czasopismo, ISSN",
    "search.form.advanced.pcj.label.publisher": "Wydawca:",
    "search.form.advanced.pcj.issn.prefix": "ISSN",
    "search.form.advanced.pcj.eissn.prefix": "eISSN",
    "search.form.advanced.pcj.publishing-companies.error.max": "Maksymalna liczba wydawców: 30",
    "search.form.advanced.pcj.journals.error.max": "Maksymalna liczba czasopism: 30",
    "spinner.sr-only": "Trwa ładowanie...",
    "modal.close.button.label": "Zamknij",
    "link.externalLink.label": ", Otwierane w nowej karcie",
    "icon.externalLink.label": ", Otwierane w nowej karcie",
    "icon.download.label": "Pobierz",
    "pbn.export.button.label": "Eksport do PBN",
    "error.general.title": "Wystąpił błąd",
    "error.general.header": "Wystąpił błąd.",
    "error.notfound.title": "Nie znaleziono strony",
    "error.notfound.header": "Nie znaleziono strony.",
    "error.accessdenied.header": "Brak dostępu.",
    "error.button.mainpage": "Przejdź do strony głównej",
    "safemail.placeholder.message": "Odkryj e-mail",
    "logout.button": "Wyloguj",
    "logout.header.loggedOut": "Wylogowany",
    "react-select.aria.live.option": "opcja",
    "react-select.aria.live.options": "opcje",
    "react-select.aria.live.guidance.menu.choose": "Użyj Strzałki w górę i Strzałki w dół aby wybrać opcje",
    "react-select.aria.live.guidance.menu.select": "naciśnij Enter aby wybrać obecnie zaznaczoną opcję",
    "react-select.aria.live.guidance.menu.exit": "naciśnij Escape aby zamknąć menu",
    "react-select.aria.live.guidance.menu.select-and-exit": "naciśnij Tab aby wybrać opcję i zamknąć menu",
    "react-select.aria.live.guidance.input.is-focused": "jest zaznaczony",
    "react-select.aria.live.guidance.input.refine": "wpisz aby zawęzić listę",
    "react-select.aria.live.guidance.input.open-menu": "naciśnij Strzałkę w dół aby otworzyć menu",
    "react-select.aria.live.guidance.input.focus-selected": "naciśnij Strzałkę w lewo aby zaznaczyć wybrane wartości",
    "react-select.aria.live.guidance.value": "Użyj Strzałki w lewo i w prawo aby przełączyć się pomiędzy zaznaczonymi wartościami, naciśnij Backspace aby usunąć obecnie zaznaczoną wartość",
    "react-select.aria.live.on-change.deselected": "odznaczona",
    "react-select.aria.live.on-change.clear": "Wszystkie wybrane opcje zostały wyczyszczone.",
    "react-select.aria.live.on-change.initial-input-focus.selected": "zaznaczona",
    "react-select.aria.live.on-change.initial-input-focus.selected.plural": "zaznaczone",
    "react-select.aria.live.on-change.select-option.disabled": "jest wyłączona. Wybierz inną opcję.",
    "react-select.aria.live.on-change.select-option.selected": "wybrana.",
    "react-select.aria.live.on-focus.of": "z",
    "react-select.aria.live.on-focus.value.value": "wartość",
    "react-select.aria.live.on-focus.value.focused": "zaznaczona",
    "react-select.aria.live.on-focus.menu.disabled": "wyłączona",
    "react-select.aria.live.on-focus.menu.selected": "wybrana",
    "react-select.aria.live.on-focus.menu.focused": "zaznaczona",
    "react-select.aria.live.on-filter": "dla wyszukiwania łańcucha znaków",
    "dictionary.citation.apa": "APA",
    "dictionary.citation.mla": "MLA",
    "dictionary.citation.ama": "AMA",
    "dictionary.citation.nlm": "NLM",
    "dictionary.citation.iso690": "ISO 690",
    "dictionary.journal_external_id_type.issn": "ISSN",
    "dictionary.journal_external_id_type.eissn": "e-ISSN",
    "dictionary.journal_external_id_type.series_issn": "ISSN serii",
    "dictionary.journal_external_id_type.series_eissn": "e-ISSN serii",
    "dictionary.journal_external_id_type.doi": "DOI",
    "dictionary.journal_additional_description_type.editorial_board": "Redakcja",
    "dictionary.journal_additional_description_type.scientific_council": "Rada naukowa",
    "dictionary.journal_additional_description_type.for_authors_and_reviewers": "Dla autorów i recenzentów",
    "dictionary.journal_additional_description_type.publishing_ethics": "Etyka wydawnicza",
    "dictionary.journal_additional_description_type.open_access_policy": "Polityka <inEnglish>Open access</inEnglish>",
    "dictionary.journal_title_type.subtitle": "Podtytuł",
    "dictionary.journal_title_type.romanization": "Latynizacja",
    "dictionary.journal_title_type.alternative": "Tytuł alternatywny",
    "dictionary.journal_title_type.abbreviation": "Skrót tytułu",
    "dictionary.journal_title_type.series": "Seria wydawnicza",
    "dictionary.journal_database_type.agro": "AGRO (czasopisma z dziedziny nauk rolniczych i przyrodniczych)",
    "dictionary.journal_database_type.baz_ekon": "BazEkon (czasopisma z zakresu nauk ekonomicznych)",
    "dictionary.journal_database_type.baz_tech": "BazTech (czasopisma z dziedziny nauk inżynieryjno-technicznych i ścisłych)",
    "dictionary.journal_database_type.cejsh": "CEJSH (czasopisma z dziedziny nauk humanistycznych, społecznych,  teologicznych i sztuki)",
    "dictionary.journal_database_type.dml_pl": "DML-PL (czasopisma z zakresu matematyki)",
    "dictionary.journal_database_type.psjd": "PSJD (czasopisma z dziedziny nauk medycznych i nauk o zdrowiu)",
    "dictionary.journal_score_type.mnisw_score": "Bieżąca punktacja ministerialna",
    "dictionary.journal_score_type.snip": "SNIP",
    "dictionary.journal_score_type.sjr": "SJR",
    "dictionary.journal_score_type.impact_factor": "<inEnglish>Impact Factor</inEnglish>",
    "dictionary.journal_score_type.impact_factor_5_years": "Pięcioletni <inEnglish>Impact Factor</inEnglish>",
    "dictionary.journal_score_type.no_html.mnisw_score": "Bieżąca punktacja ministerialna",
    "dictionary.journal_score_type.no_html.snip": "SNIP",
    "dictionary.journal_score_type.no_html.sjr": "SJR",
    "dictionary.journal_score_type.no_html.impact_factor": "Impact Factor",
    "dictionary.journal_score_type.no_html.impact_factor_5_years": "Pięcioletni Impact Factor",
    "dictionary.journal_issue_frequency_type.daily": "Dziennik",
    "dictionary.journal_issue_frequency_type.weekly": "Tygodnik",
    "dictionary.journal_issue_frequency_type.biweekly": "Dwutygodnik",
    "dictionary.journal_issue_frequency_type.monthly": "Miesięcznik",
    "dictionary.journal_issue_frequency_type.bimonthly": "Dwumiesięcznik",
    "dictionary.journal_issue_frequency_type.quarterly": "Kwartalnik",
    "dictionary.journal_issue_frequency_type.semiannually": "Półrocznik",
    "dictionary.journal_issue_frequency_type.annually": "Rocznik",
    "dictionary.journal_issue_frequency_type.irregularly_published": "Publikacja nieregularna",
    "dictionary.article_external_id_type.doi": "DOI",
    "dictionary.article_external_id_type.pubmed_id": "PMID",
    "dictionary.book_external_id_type.doi": "DOI",
    "dictionary.book_external_id_type.pubmed_id": "PMID",
    "dictionary.book_external_id_type.isbn": "ISBN",
    "dictionary.chapter_external_id_type.doi": "DOI",
    "dictionary.chapter_external_id_type.pubmed_id": "PMID",
    "dictionary.publication_date_type.received": "Przyjęto",
    "dictionary.publication_date_type.published": "Opublikowano",
    "dictionary.publication_date_type.accepted": "Zaakceptowano",
    "dictionary.publication_date_type.released_online": "Wydano online",
    "dictionary.article_type.original_scientific_text": "Oryginalny tekst naukowy",
    "dictionary.article_type.review": "Recenzja",
    "dictionary.article_type.translation": "Przekład krytyczny",
    "dictionary.article_type.other_scientific_publication": "Inna publikacja naukowa",
    "dictionary.simple_book_type.original_scientific_text": "Oryginalny tekst naukowy",
    "dictionary.simple_book_type.translation": "Przekład krytyczny",
    "dictionary.simple_book_type.scientific_edition_of_source_material": "Edycja naukowa materiałów źródłowych",
    "dictionary.simple_book_type.other_scientific_publication": "Inna publikacja naukowa",
    "dictionary.collective_work_type.original_scientific_text": "Oryginalny tekst naukowy",
    "dictionary.collective_work_type.other_scientific_publication": "Inna publikacja naukowa",
    "dictionary.chapter_type.original_scientific_text": "Oryginalny tekst naukowy",
    "dictionary.chapter_type.review": "Recenzja",
    "dictionary.chapter_type.foreword": "Wstęp/Przedmowa",
    "dictionary.chapter_type.afterword": "Posłowie",
    "dictionary.chapter_type.translation": "Przekład krytyczny",
    "dictionary.chapter_type.scientific_edition_of_source_material": "Edycja naukowa materiałów źródłowych",
    "dictionary.chapter_type.index": "Indeks",
    "dictionary.chapter_type.other_scientific_publication": "Inna publikacja naukowa",
    "dictionary.contributor_role.author": "Autor",
    "dictionary.contributor_role.editor": "Redaktor",
    "dictionary.contributor_role.scientific_editor": "Redaktor naukowy",
    "dictionary.contributor_role.reviewer": "Recenzent",
    "dictionary.contributor_role.translator": "Tłumacz",
    "dictionary.contributor_role.authors": "Autorzy",
    "dictionary.contributor_role.editors": "Redaktorzy",
    "dictionary.contributor_role.scientific_editors": "Redaktorzy naukowi",
    "dictionary.contributor_role.reviewers": "Recenzenci",
    "dictionary.contributor_role.translators": "Tłumacze",
    "dictionary.contributor_role.author.corresponding": "Autor korespondencyjny",
    "dictionary.contributor_role.editor.corresponding": "Redaktor korespondencyjny",
    "dictionary.contributor_role.scientific_editor.corresponding": "Redaktor naukowy korespondencyjny",
    "dictionary.contributor_role.reviewer.corresponding": "Recenzent korespondencyjny",
    "dictionary.contributor_role.translator.corresponding": "Tłumacz korespondencyjny",
    "dictionary.contributor_role.authors.corresponding": "Autorzy korespondencyjny",
    "dictionary.contributor_role.editors.corresponding": "Redaktorzy korespondencyjny",
    "dictionary.contributor_role.scientific_editors.corresponding": "Redaktorzy naukowi korespondencyjny",
    "dictionary.contributor_role.reviewers.corresponding": "Recenzenci korespondencyjni",
    "dictionary.contributor_role.translators.corresponding": "Tłumacze korespondencyjni",
    "dictionary.language.POL": "Polski",
    "dictionary.language.ENG": "Angielski",
    "dictionary.language.FRA": "Francuski",
    "dictionary.language.DEU": "Niemiecki",
    "dictionary.language.RUS": "Rosyjski",
    "dictionary.language.ABK": "Abchaski",
    "dictionary.language.AAR": "Afar",
    "dictionary.language.AFR": "Afrikaans",
    "dictionary.language.AYM": "Ajmara",
    "dictionary.language.SQI": "Albański",
    "dictionary.language.AMH": "Amharski",
    "dictionary.language.ARA": "Arabski",
    "dictionary.language.ASM": "Asamski",
    "dictionary.language.AZE": "Azerbejdżański",
    "dictionary.language.EUS": "Baskijski",
    "dictionary.language.BAK": "Baszkirski",
    "dictionary.language.BEN": "Bengalski",
    "dictionary.language.BEL": "Białoruski",
    "dictionary.language.BIH": "Biharski",
    "dictionary.language.MYA": "Birmański",
    "dictionary.language.BIS": "Bislama",
    "dictionary.language.BRE": "Bretoński",
    "dictionary.language.BUL": "Bułgarski",
    "dictionary.language.ZHO": "Chiński",
    "dictionary.language.HRV": "Chorwacki",
    "dictionary.language.CES": "Czeski",
    "dictionary.language.DAN": "Duński",
    "dictionary.language.DZO": "Dżonkgha",
    "dictionary.language.EPO": "Esperanto",
    "dictionary.language.EST": "Estoński",
    "dictionary.language.FAO": "Farerski",
    "dictionary.language.FIJ": "Fidżijski",
    "dictionary.language.FIN": "Fiński",
    "dictionary.language.FRY": "Fryzyjski",
    "dictionary.language.GLA": "Gaelic",
    "dictionary.language.GLG": "Galicyjski",
    "dictionary.language.ELL": "Grecki",
    "dictionary.language.KAL": "Grenlandzki",
    "dictionary.language.KAT": "Gruziński",
    "dictionary.language.GRN": "Guarani",
    "dictionary.language.GUJ": "Gudżarati",
    "dictionary.language.HAU": "Hausa",
    "dictionary.language.IKU": "Hebrajski",
    "dictionary.language.HIN": "Hindi",
    "dictionary.language.SPA": "Hiszpański",
    "dictionary.language.NLD": "Holenderski",
    "dictionary.language.INA": "Interlingua",
    "dictionary.language.ILE": "Interlingue",
    "dictionary.language.IPK": "Inupiak",
    "dictionary.language.GLE": "Irlandzki",
    "dictionary.language.ISL": "Islandzki",
    "dictionary.language.JPN": "Japoński",
    "dictionary.language.JAV": "Jawajski",
    "dictionary.language.YOR": "Joruba",
    "dictionary.language.KAN": "Kannada",
    "dictionary.language.KAS": "Kaszmirski",
    "dictionary.language.CAT": "Kataloński",
    "dictionary.language.KAZ": "Kazachski",
    "dictionary.language.QUE": "Keczua",
    "dictionary.language.KHM": "Khmerski",
    "dictionary.language.KIR": "Kirgiski",
    "dictionary.language.KOR": "Koreański",
    "dictionary.language.COS": "Korsykański",
    "dictionary.language.KUR": "Kurdyjski",
    "dictionary.language.LAT": "Łaciński",
    "dictionary.language.LAO": "Laotański",
    "dictionary.language.LIN": "Lingala",
    "dictionary.language.LIT": "Litewski",
    "dictionary.language.LAV": "Łotewski",
    "dictionary.language.MKD": "Macedoński",
    "dictionary.language.MLG": "Malagaski",
    "dictionary.language.MAL": "Malajalamski",
    "dictionary.language.MSA": "Malajski",
    "dictionary.language.MLT": "Maltański",
    "dictionary.language.MRI": "Maoryski",
    "dictionary.language.MAR": "Marathi",
    "dictionary.language.MON": "Mongolski",
    "dictionary.language.NAU": "Nauruański",
    "dictionary.language.NEP": "Nepalski",
    "dictionary.language.NOR": "Norweski",
    "dictionary.language.ORI": "Orija",
    "dictionary.language.HYE": "Ormiański",
    "dictionary.language.ORM": "Oromo",
    "dictionary.language.PUS": "Paszto",
    "dictionary.language.PAN": "Pendżabski",
    "dictionary.language.FAS": "Perski",
    "dictionary.language.POR": "Portugalski",
    "dictionary.language.OCI": "Prowansalski",
    "dictionary.language.ROH": "Retoromański",
    "dictionary.language.KIN": "Ruanda-Rundi",
    "dictionary.language.RON": "Rumuński",
    "dictionary.language.RUN": "Rundi",
    "dictionary.language.SMO": "Samoański",
    "dictionary.language.SAG": "Sangro",
    "dictionary.language.SAN": "Sanskryt",
    "dictionary.language.SRP": "Serbski",
    "dictionary.language.SNA": "Shona",
    "dictionary.language.SND": "Sindhi",
    "dictionary.language.SLK": "Słowacki",
    "dictionary.language.SLV": "Słoweński",
    "dictionary.language.SOM": "Somalijski",
    "dictionary.language.SOT": "Sotho",
    "dictionary.language.SWA": "Suahili",
    "dictionary.language.SSW": "Suazi",
    "dictionary.language.SUN": "Sudański",
    "dictionary.language.SIN": "Syngaleski",
    "dictionary.language.SWE": "Szwedzki",
    "dictionary.language.TGK": "Tadżycki",
    "dictionary.language.TGL": "Tagalski",
    "dictionary.language.THA": "Tajski",
    "dictionary.language.TAM": "Tamilski",
    "dictionary.language.TAT": "Tatarski",
    "dictionary.language.TEL": "Telugu",
    "dictionary.language.TIR": "Tigrinya",
    "dictionary.language.TON": "Tonga",
    "dictionary.language.TSO": "Tsonga",
    "dictionary.language.TSN": "Tswana",
    "dictionary.language.TUR": "Turecki",
    "dictionary.language.TUK": "Turkmeński",
    "dictionary.language.TWI": "Twi",
    "dictionary.language.BOD": "Tybetański",
    "dictionary.language.UKR": "Ukraiński",
    "dictionary.language.URD": "Urdu",
    "dictionary.language.UZB": "Uzbecki",
    "dictionary.language.VOL": "Volapuk",
    "dictionary.language.CYM": "Walijski",
    "dictionary.language.HUN": "Węgierski",
    "dictionary.language.VIE": "Wietnamski",
    "dictionary.language.ITA": "Włoski",
    "dictionary.language.WOL": "Wolof",
    "dictionary.language.XHO": "Xhosa",
    "dictionary.language.ZUL": "Zulu",
    "dictionary.language.ACE": "Achinese",
    "dictionary.language.ACH": "Acoli",
    "dictionary.language.ADA": "Adangme",
    "dictionary.language.ADY": "Adyghe",
    "dictionary.language.AFH": "Afrihili",
    "dictionary.language.AFA": "Afro-Asiatic languages",
    "dictionary.language.AIN": "Ainu",
    "dictionary.language.AKA": "Akan",
    "dictionary.language.AKK": "Akkadian",
    "dictionary.language.ALE": "Aleut",
    "dictionary.language.ALG": "Algonquian languages",
    "dictionary.language.TUT": "Altaic languages",
    "dictionary.language.ANP": "Angika",
    "dictionary.language.APA": "Apache languages",
    "dictionary.language.ARG": "Aragonese",
    "dictionary.language.ARP": "Arapaho",
    "dictionary.language.ARW": "Arawak",
    "dictionary.language.RUP": "Aromanian",
    "dictionary.language.ART": "Artificial languages",
    "dictionary.language.AST": "Asturian",
    "dictionary.language.ATH": "Athapascan languages",
    "dictionary.language.AUS": "Australian languages",
    "dictionary.language.MAP": "Austronesian languages",
    "dictionary.language.AVA": "Avaric",
    "dictionary.language.AVE": "Avestan",
    "dictionary.language.AWA": "Awadhi",
    "dictionary.language.BAN": "Balinese",
    "dictionary.language.BAT": "Baltic languages",
    "dictionary.language.BAL": "Baluchi",
    "dictionary.language.BAM": "Bambara",
    "dictionary.language.BAI": "Bamileke languages",
    "dictionary.language.BAD": "Banda languages",
    "dictionary.language.BNT": "Bantu languages",
    "dictionary.language.BAS": "Basa",
    "dictionary.language.BTK": "Batak languages",
    "dictionary.language.BEJ": "Beja",
    "dictionary.language.BEM": "Bemba",
    "dictionary.language.BER": "Berber languages",
    "dictionary.language.BHO": "Bhojpuri",
    "dictionary.language.BIK": "Bikol",
    "dictionary.language.BIN": "Bini",
    "dictionary.language.BYN": "Blin",
    "dictionary.language.ZBL": "Blissymbols",
    "dictionary.language.NOB": "Bokmål, Norwegian",
    "dictionary.language.BOS": "Bosnian",
    "dictionary.language.BRA": "Braj",
    "dictionary.language.BUG": "Buginese",
    "dictionary.language.BUA": "Buriat",
    "dictionary.language.CAD": "Caddo",
    "dictionary.language.CAU": "Caucasian languages",
    "dictionary.language.CEB": "Cebuano",
    "dictionary.language.CEL": "Celtic languages",
    "dictionary.language.CAI": "Central American Indian languages",
    "dictionary.language.CHG": "Chagatai",
    "dictionary.language.CMC": "Chamic languages",
    "dictionary.language.CHA": "Chamorro",
    "dictionary.language.CHE": "Chechen",
    "dictionary.language.CHR": "Cherokee",
    "dictionary.language.CHY": "Cheyenne",
    "dictionary.language.CHB": "Chibcha",
    "dictionary.language.NYA": "Chichewa",
    "dictionary.language.CHN": "Chinook jargon",
    "dictionary.language.CHP": "Chipewyan",
    "dictionary.language.CHO": "Choctaw",
    "dictionary.language.CHU": "Church Slavic",
    "dictionary.language.CHK": "Chuukese",
    "dictionary.language.CHV": "Chuvash",
    "dictionary.language.NWC": "Classical Newari",
    "dictionary.language.SYC": "Classical Syriac",
    "dictionary.language.COP": "Coptic",
    "dictionary.language.COR": "Cornish",
    "dictionary.language.CRE": "Cree",
    "dictionary.language.MUS": "Creek",
    "dictionary.language.CRP": "Creoles and pidgins",
    "dictionary.language.CPE": "Creoles and pidgins, English based",
    "dictionary.language.CPF": "Creoles and pidgins, French-based",
    "dictionary.language.CPP": "Creoles and pidgins, Portuguese-based",
    "dictionary.language.CRH": "Crimean Tatar",
    "dictionary.language.CUS": "Cushitic languages",
    "dictionary.language.DAK": "Dakota",
    "dictionary.language.DAR": "Dargwa",
    "dictionary.language.DEL": "Delaware",
    "dictionary.language.DIN": "Dinka",
    "dictionary.language.DIV": "Divehi",
    "dictionary.language.DOI": "Dogri",
    "dictionary.language.DGR": "Dogrib",
    "dictionary.language.DRA": "Dravidian languages",
    "dictionary.language.DUA": "Duala",
    "dictionary.language.DUM": "Dutch, Middle (ca.1050-1350)",
    "dictionary.language.DYU": "Dyula",
    "dictionary.language.FRS": "Eastern Frisian",
    "dictionary.language.EFI": "Efik",
    "dictionary.language.EGY": "Egyptian (Ancient)",
    "dictionary.language.EKA": "Ekajuk",
    "dictionary.language.ELX": "Elamite",
    "dictionary.language.ENM": "English, Middle (1100-1500)",
    "dictionary.language.ANG": "English, Old (ca.450-1100)",
    "dictionary.language.MYV": "Erzya",
    "dictionary.language.EWE": "Ewe",
    "dictionary.language.EWO": "Ewondo",
    "dictionary.language.FAN": "Fang",
    "dictionary.language.FAT": "Fanti",
    "dictionary.language.FIL": "Filipino",
    "dictionary.language.FIU": "Finno-Ugrian languages",
    "dictionary.language.FON": "Fon",
    "dictionary.language.FRM": "French, Middle (ca.1400-1600)",
    "dictionary.language.FRO": "French, Old (842-ca.1400)",
    "dictionary.language.FUR": "Friulian",
    "dictionary.language.FUL": "Fulah",
    "dictionary.language.GAA": "Ga",
    "dictionary.language.CAR": "Galibi Carib",
    "dictionary.language.LUG": "Ganda",
    "dictionary.language.GAY": "Gayo",
    "dictionary.language.GBA": "Gbaya",
    "dictionary.language.GEZ": "Geez",
    "dictionary.language.GMH": "German, Middle High (ca.1050-1500)",
    "dictionary.language.GOH": "German, Old High (ca.750-1050)",
    "dictionary.language.GEM": "Germanic languages",
    "dictionary.language.GIL": "Gilbertese",
    "dictionary.language.GON": "Gondi",
    "dictionary.language.GOR": "Gorontalo",
    "dictionary.language.GOT": "Gothic",
    "dictionary.language.GRB": "Grebo",
    "dictionary.language.GRC": "Greek, Ancient (to 1453)",
    "dictionary.language.GWI": "Gwich'in",
    "dictionary.language.HAI": "Haida",
    "dictionary.language.HAT": "Haitian",
    "dictionary.language.HAW": "Hawaiian",
    "dictionary.language.HEB": "Hebrew",
    "dictionary.language.HER": "Herero",
    "dictionary.language.HIL": "Hiligaynon",
    "dictionary.language.HIM": "Himachali languages",
    "dictionary.language.HMO": "Hiri Motu",
    "dictionary.language.HIT": "Hittite",
    "dictionary.language.HMN": "Hmong",
    "dictionary.language.HUP": "Hupa",
    "dictionary.language.IBA": "Iban",
    "dictionary.language.IDO": "Ido",
    "dictionary.language.IBO": "Igbo",
    "dictionary.language.IJO": "Ijo languages",
    "dictionary.language.ILO": "Iloko",
    "dictionary.language.SMN": "Inari Sami",
    "dictionary.language.INC": "Indic languages",
    "dictionary.language.INE": "Indo-European languages",
    "dictionary.language.IND": "Indonesian",
    "dictionary.language.INH": "Ingush",
    "dictionary.language.IRA": "Iranian languages",
    "dictionary.language.MGA": "Irish, Middle (900-1200)",
    "dictionary.language.SGA": "Irish, Old (to 900)",
    "dictionary.language.IRO": "Iroquoian languages",
    "dictionary.language.JRB": "Judeo-Arabic",
    "dictionary.language.JPR": "Judeo-Persian",
    "dictionary.language.KBD": "Kabardian",
    "dictionary.language.KAB": "Kabyle",
    "dictionary.language.KAC": "Kachin",
    "dictionary.language.XAL": "Kalmyk",
    "dictionary.language.KAM": "Kamba",
    "dictionary.language.KAU": "Kanuri",
    "dictionary.language.KAA": "Kara-Kalpak",
    "dictionary.language.KRC": "Karachay-Balkar",
    "dictionary.language.KRL": "Karelian",
    "dictionary.language.KAR": "Karen languages",
    "dictionary.language.CSB": "Kashubian",
    "dictionary.language.KAW": "Kawi",
    "dictionary.language.KHA": "Khasi",
    "dictionary.language.KHI": "Khoisan languages",
    "dictionary.language.KHO": "Khotanese",
    "dictionary.language.KIK": "Kikuyu",
    "dictionary.language.KMB": "Kimbundu",
    "dictionary.language.TLH": "Klingon",
    "dictionary.language.KOM": "Komi",
    "dictionary.language.KON": "Kongo",
    "dictionary.language.KOK": "Konkani",
    "dictionary.language.KOS": "Kosraean",
    "dictionary.language.KPE": "Kpelle",
    "dictionary.language.KRO": "Kru languages",
    "dictionary.language.KUA": "Kuanyama",
    "dictionary.language.KUM": "Kumyk",
    "dictionary.language.KRU": "Kurukh",
    "dictionary.language.KUT": "Kutenai",
    "dictionary.language.LAD": "Ladino",
    "dictionary.language.LAH": "Lahnda",
    "dictionary.language.LAM": "Lamba",
    "dictionary.language.DAY": "Land Dayak languages",
    "dictionary.language.LEZ": "Lezghian",
    "dictionary.language.LIM": "Limburgan",
    "dictionary.language.JBO": "Lojban",
    "dictionary.language.NDS": "Low German",
    "dictionary.language.DSB": "Lower Sorbian",
    "dictionary.language.LOZ": "Lozi",
    "dictionary.language.LUB": "Luba-Katanga",
    "dictionary.language.LUA": "Luba-Lulua",
    "dictionary.language.LUI": "Luiseno",
    "dictionary.language.SMJ": "Lule Sami",
    "dictionary.language.LUN": "Lunda",
    "dictionary.language.LUO": "Luo (Kenya and Tanzania)",
    "dictionary.language.LUS": "Lushai",
    "dictionary.language.LTZ": "Luxembourgish",
    "dictionary.language.MAD": "Madurese",
    "dictionary.language.MAG": "Magahi",
    "dictionary.language.MAI": "Maithili",
    "dictionary.language.MAK": "Makasar",
    "dictionary.language.MNC": "Manchu",
    "dictionary.language.MDR": "Mandar",
    "dictionary.language.MAN": "Mandingo",
    "dictionary.language.MNI": "Manipuri",
    "dictionary.language.MNO": "Manobo languages",
    "dictionary.language.GLV": "Manx",
    "dictionary.language.ARN": "Mapudungun",
    "dictionary.language.CHM": "Mari",
    "dictionary.language.MAH": "Marshallese",
    "dictionary.language.MWR": "Marwari",
    "dictionary.language.MAS": "Masai",
    "dictionary.language.MYN": "Mayan languages",
    "dictionary.language.MEN": "Mende",
    "dictionary.language.MIC": "Mi'kmaq",
    "dictionary.language.MIN": "Minangkabau",
    "dictionary.language.MWL": "Mirandese",
    "dictionary.language.MOH": "Mohawk",
    "dictionary.language.MDF": "Moksha",
    "dictionary.language.MKH": "Mon-Khmer languages",
    "dictionary.language.LOL": "Mongo",
    "dictionary.language.MOS": "Mossi",
    "dictionary.language.MUN": "Munda languages",
    "dictionary.language.NQO": "N'Ko",
    "dictionary.language.NAH": "Nahuatl languages",
    "dictionary.language.NAV": "Navajo",
    "dictionary.language.NDE": "Ndebele, North",
    "dictionary.language.NBL": "Ndebele, South",
    "dictionary.language.NDO": "Ndonga",
    "dictionary.language.NAP": "Neapolitan",
    "dictionary.language.NEW": "Nepal Bhasa",
    "dictionary.language.NIA": "Nias",
    "dictionary.language.NIC": "Niger-Kordofanian languages",
    "dictionary.language.SSA": "Nilo-Saharan languages",
    "dictionary.language.NIU": "Niuean",
    "dictionary.language.NOG": "Nogai",
    "dictionary.language.NON": "Norse, Old",
    "dictionary.language.NAI": "North American Indian languages",
    "dictionary.language.FRR": "Northern Frisian",
    "dictionary.language.SME": "Northern Sami",
    "dictionary.language.NNO": "Norwegian Nynorsk",
    "dictionary.language.NUB": "Nubian languages",
    "dictionary.language.NYM": "Nyamwezi",
    "dictionary.language.NYN": "Nyankole",
    "dictionary.language.NYO": "Nyoro",
    "dictionary.language.NZI": "Nzima",
    "dictionary.language.ARC": "Official Aramaic (700-300 BCE)",
    "dictionary.language.OJI": "Ojibwa",
    "dictionary.language.OSA": "Osage",
    "dictionary.language.OSS": "Ossetian",
    "dictionary.language.OTO": "Otomian languages",
    "dictionary.language.PAL": "Pahlavi",
    "dictionary.language.PAU": "Palauan",
    "dictionary.language.PLI": "Pali",
    "dictionary.language.PAM": "Pampanga",
    "dictionary.language.PAG": "Pangasinan",
    "dictionary.language.PAP": "Papiamento",
    "dictionary.language.PAA": "Papuan languages",
    "dictionary.language.NSO": "Pedi",
    "dictionary.language.PEO": "Persian, Old (ca.600-400 B.C.)",
    "dictionary.language.PHI": "Philippine languages",
    "dictionary.language.PHN": "Phoenician",
    "dictionary.language.PON": "Pohnpeian",
    "dictionary.language.PRA": "Prakrit languages",
    "dictionary.language.PRO": "Provençal, Old (to 1500)",
    "dictionary.language.RAJ": "Rajasthani",
    "dictionary.language.RAP": "Rapanui",
    "dictionary.language.RAR": "Rarotongan",
    "dictionary.language.ROA": "Romance languages",
    "dictionary.language.ROM": "Romany",
    "dictionary.language.SAL": "Salishan languages",
    "dictionary.language.SAM": "Samaritan Aramaic",
    "dictionary.language.SMI": "Sami languages",
    "dictionary.language.SAD": "Sandawe",
    "dictionary.language.SAT": "Santali",
    "dictionary.language.SRD": "Sardinian",
    "dictionary.language.SAS": "Sasak",
    "dictionary.language.SCO": "Scots",
    "dictionary.language.SEL": "Selkup",
    "dictionary.language.SEM": "Semitic languages",
    "dictionary.language.SRR": "Serer",
    "dictionary.language.SHN": "Shan",
    "dictionary.language.III": "Sichuan Yi",
    "dictionary.language.SCN": "Sicilian",
    "dictionary.language.SID": "Sidamo",
    "dictionary.language.SGN": "Sign Languages",
    "dictionary.language.BLA": "Siksika",
    "dictionary.language.SIT": "Sino-Tibetan languages",
    "dictionary.language.SIO": "Siouan languages",
    "dictionary.language.SMS": "Skolt Sami",
    "dictionary.language.DEN": "Slave (Athapascan)",
    "dictionary.language.SLA": "Slavic languages",
    "dictionary.language.SOG": "Sogdian",
    "dictionary.language.SON": "Songhai languages",
    "dictionary.language.SNK": "Soninke",
    "dictionary.language.WEN": "Sorbian languages",
    "dictionary.language.SAI": "South American Indian languages",
    "dictionary.language.ALT": "Southern Altai",
    "dictionary.language.SMA": "Southern Sami",
    "dictionary.language.SRN": "Sranan Tongo",
    "dictionary.language.SUK": "Sukuma",
    "dictionary.language.SUX": "Sumerian",
    "dictionary.language.SUS": "Susu",
    "dictionary.language.GSW": "Swiss German",
    "dictionary.language.SYR": "Syriac",
    "dictionary.language.TAH": "Tahitian",
    "dictionary.language.TAI": "Tai languages",
    "dictionary.language.TMH": "Tamashek",
    "dictionary.language.TER": "Tereno",
    "dictionary.language.TET": "Tetum",
    "dictionary.language.TIG": "Tigre",
    "dictionary.language.TEM": "Timne",
    "dictionary.language.TIV": "Tiv",
    "dictionary.language.TLI": "Tlingit",
    "dictionary.language.TPI": "Tok Pisin",
    "dictionary.language.TKL": "Tokelau",
    "dictionary.language.TOG": "Tonga (Nyasa)",
    "dictionary.language.TSI": "Tsimshian",
    "dictionary.language.TUM": "Tumbuka",
    "dictionary.language.TUP": "Tupi languages",
    "dictionary.language.OTA": "Turkish, Ottoman (1500-1928)",
    "dictionary.language.TVL": "Tuvalu",
    "dictionary.language.TYV": "Tuvinian",
    "dictionary.language.UDM": "Udmurt",
    "dictionary.language.UGA": "Ugaritic",
    "dictionary.language.UIG": "Uighur",
    "dictionary.language.UMB": "Umbundu",
    "dictionary.language.HSB": "Upper Sorbian",
    "dictionary.language.VAI": "Vai",
    "dictionary.language.VEN": "Venda",
    "dictionary.language.VOT": "Votic",
    "dictionary.language.WAK": "Wakashan languages",
    "dictionary.language.WLN": "Walloon",
    "dictionary.language.WAR": "Waray",
    "dictionary.language.WAS": "Washo",
    "dictionary.language.WAL": "Wolaitta",
    "dictionary.language.SAH": "Yakut",
    "dictionary.language.YAO": "Yao",
    "dictionary.language.YAP": "Yapese",
    "dictionary.language.YID": "Yiddish",
    "dictionary.language.YPK": "Yupik languages",
    "dictionary.language.ZND": "Zande languages",
    "dictionary.language.ZAP": "Zapotec",
    "dictionary.language.ZZA": "Zaza",
    "dictionary.language.ZEN": "Zenaga",
    "dictionary.language.ZHA": "Zhuang",
    "dictionary.language.ZUN": "Zuni",
    "dictionary.language.MIS": "Niekodowane języki",
    "dictionary.language.MUL": "Wiele języków",
    "dictionary.language.UND": "Nieokreślony",
    "dictionary.language.ZXX": "Brak treści językowych",
  }
};
